import React, { lazy } from "react";

import { IRoute } from "../Routes.types";

const Login = lazy(() => import("pages/login"));
const Users = lazy(() => import("pages/users"));
const Plans = lazy(() => import("pages/plans"));
const Cashback = lazy(() => import("pages/cashback"));
const Version = lazy(() => import("pages/versions"));
const News = lazy(() => import("pages/news"));
const Transaction = lazy(() => import("pages/transaction"));

export const routes: IRoute[] = [
  {
    key: "user",
    isPrivate: true,
    path: "users/*",
    element: <Users />,
  },
  {
    key: "plan",
    isPrivate: true,
    path: "plans/*",
    element: <Plans />,
  },
  {
    key: "cashback",
    isPrivate: true,
    path: "cashback/*",
    element: <Cashback />,
  },
  {
    key: "version",
    isPrivate: true,
    path: "version/*",
    element: <Version />,
  },
  {
    key: "news",
    isPrivate: true,
    path: "news/*",
    element: <News />,
  },
  {
    key: "login",
    isPrivate: false,
    path: "login/*",
    element: <Login />,
  },
  {
    key: "transaction",
    isPrivate: false,
    path: "transaction/*",
    element: <Transaction />,
  },
];
