import React, { useEffect, useState, FC, Fragment } from "react";
import get from "lodash.get";
// import TableCell from "@mui/material";
// import TableRow from "@mui/material/TableRow";
import { Checkbox, TableCell, TableRow } from "@mui/material";
import { REQUEST_STATUS, useRequest } from "hooks";
import { SearchParams } from "services/urlParams/urlParams";
import Loader from "pages/loader/container/Loader";
import { TableFooter, TableHead } from "./components/index";
import {
  StyledTable,
  TableBodyStyled,
  TableCellCheckbox,
  TableContainerStyle,
  TableWrapperStyle,
} from "./Table.style";
import { ITableProps } from "./Table.types";
import { dragLeaveHandler } from "./utils/dragLeaveHandler/DragLeaveHandler";
import { dragEndHandler } from "./utils/dragEndHandler/DragEndHandler";
import { dragOverHandler } from "./utils/dragOverHandler/DragOverHandler";
import { useLocation } from "react-router-dom";

const Table: FC<ITableProps> = ({
  columns,
  useIdForKey = false,
  onRowClick,
  dataUrl,
  optional = {},
  selection = false,
  draggable = false,
  draggableChange = () => {},
  handleCheckboxChange = () => {},
  render = false,
  filter = "",
}) => {
  const [client, res, status, error] = useRequest();
  const [setParams, getParams] = SearchParams();
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [selected, setSelected] = useState<readonly string[]>([]);
  const [currentItem, setCurrentItem] = useState<any>();
  const [fetchData, setFetchData] = useState<any[]>([]);

  useEffect(() => {
    let newQueryData = {
      limit: getParams().limit ? parseInt(getParams().limit) : 10,
      page: getParams().page ? parseInt(getParams().page) : 1,
      sortOrder: getParams().sortOrder ? parseInt(getParams().sortOrder) : 1,
      sortBy: getParams().sortBy ? getParams().sortBy : "balance",
    };
    setPage(newQueryData.page);
    setRowsPerPage(newQueryData.limit);
    fetchDataFunction(newQueryData);
  }, [page, rowsPerPage, render, filter]);

  const fetchDataFunction = async ({ limit, page, sortOrder, sortBy }: any) => {
    setParams({
      ...getParams(),
      ...filter,
      limit,
      page,
      sortOrder,
      sortBy,
      ...optional,
    });

    if (!!dataUrl) {
      await client.post(dataUrl, getParams());
    }
  };

  useEffect(() => {
    if (status === REQUEST_STATUS.success) {
      setFetchData(get(res, "data.data"));

      if (selection) {
        let defaultSelected: string[] = [];
        get(res, "data.data")?.forEach((item: any) => {
          if (item.isChecked) {
            defaultSelected.push(item._id);
          }
        });
        setSelected(defaultSelected);
      }
    }
  }, [status]);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
    setParams({
      ...getParams(),
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (event: any) => {
    if (typeof event?.target?.value === "object") {
      setParams({
        ...getParams(),
        page: 1,
        limit: 0,
      });
      setRowsPerPage(0);
    } else {
      setParams({
        ...getParams(),
        page: 1,
        limit: parseInt(event.target.value),
      });
      setRowsPerPage(parseInt(event?.target?.value));
    }
    setPage(1);
  };

  const handleClick = (event: any, _id: string) => {
    let checkboxChangeQuery = {
      checked: event.target.checked,
      _id,
    };
    handleCheckboxChange(checkboxChangeQuery);
    const selectedIndex = selected.indexOf(_id);
    let newSelected: readonly string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (_id: string) => selected.indexOf(_id) !== -1;

  function dragStartHandler(e: any, item: any) {
    setCurrentItem(item);
  }

  const dropHandler = async (e: any, item: any) => {
    e.preventDefault();
    let filterFetchData = fetchData?.filter(
      (fetchDataItem: any) => fetchDataItem._id !== currentItem._id
    );
    setFetchData(filterFetchData);
    const currentIndex = fetchData?.indexOf(item);
    filterFetchData.splice(currentIndex, 0, currentItem);
    let fetchDataIdes: string[] = [];
    fetchDataIdes = filterFetchData.map((item: any) => item._id);
    await draggableChange({ _id: currentItem._id, position: currentIndex });
  };

  // if (status === REQUEST_STATUS.failed)
  //   return <div>{error?.response?.data?.message}</div>;

  return (
    <TableWrapperStyle>
      <TableContainerStyle>
        <StyledTable stickyHeader aria-label="sticky table">
          <TableHead columns={columns} selection={selection} />
          <TableBodyStyled>
            {fetchData?.map((item: any, index: number) => {
              const isItemSelected = isSelected(item._id);
              const labelId = `enhanced-table-checkbox-${index}`;
              return (
                <Fragment key={"tableRowKey" + index}>
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    // onDragOver={(e) => dragOverHandler(e)}
                    // onDragLeave={(e) => dragLeaveHandler(e)}
                    // onDragStart={(e) => dragStartHandler(e, item)}
                    // onDragEnd={(e) => dragEndHandler(e)}
                    // onDrop={(e) => dropHandler(e, item)}
                    // draggable={rowsPerPage === -1 && draggable}
                    onClick={(event: any) => {
                      onRowClick && onRowClick(item);
                    }}
                    selected={isItemSelected}
                    className={`item ${columns?.length > 1 ? "columns" : ""}`}
                  >
                    {selection && (
                      <TableCellCheckbox
                        onClick={(e: any) => e.stopPropagation()}
                      >
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          onClick={(event: any) => handleClick(event, item._id)}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCellCheckbox>
                    )}

                    {!selection && (
                      <TableCell key={index}>
                        {index + 1 + (getParams().page - 1) * getParams().limit}
                      </TableCell>
                    )}
                    {columns.map((column, index1) =>
                      column.renderItem ? (
                        <TableCell
                          key={index1 + "index1"}
                          align={
                            index1 + 1 === columns.length ? "right" : "left"
                          }
                        >
                          {column.renderItem(item, column, index1)}
                        </TableCell>
                      ) : (
                        <TableCell key={index1 + "index1"}>
                          {get(item, `${column.dataKey}`)}
                        </TableCell>
                      )
                    )}
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBodyStyled>
          <TableFooter
            draggable={draggable}
            colSpan={columns.length + 1}
            count={get(res, "data.total")}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </StyledTable>
      </TableContainerStyle>

      {status === REQUEST_STATUS.loading && <Loader />}
      <div className="normalize-table"></div>
    </TableWrapperStyle>
  );
};

export default Table;
