import React from "react";
import { useTranslation } from "react-i18next";
import { GoBackButtonStyled } from "./GoBackButton.style";
import { useNavigate } from "react-router-dom";
import BackIcon from "assets/icons/BackIcon";

const GoBackButton = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <GoBackButtonStyled onClick={() => navigate(-1)}>
      <BackIcon />
      <span className="ms-1"> Orqaga</span>
    </GoBackButtonStyled>
  );
};

export default GoBackButton;
