import get from "lodash.get";
import styled from "styled-components";

export const GoBackButtonStyled = styled("button")`
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  transition: color 0.2s ease;
  cursor: pointer;
  padding: 8px 20px;
  height: fit-content;
  background-color: rgba(55, 148, 185, 0.1);
  font-size: 14px;
  color: #3794b9;
  &:hover {
    /* background-color: #f4f6fa; */
    color: ${({ theme }) => get(theme, "primary.main")};
    svg {
      path {
        fill: ${({ theme }) => get(theme, "primary.main")};
      }
    }
  }
  svg {
    width: 23px;
    height: 23px;
    object-fit: contain;
    path {
      transition: color 0.2s ease;
    }
  }

  span {
    font-weight: 600 !important;
  }
`;
